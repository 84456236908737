<template>
  <vs-popup id="correction-time-modal" :title="title" :active="visible" @close="onClickClose" class="correction-time-modal">
    <template v-if="data">
      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Für Datum:</vs-col>
        <vs-col vs-w="9">
          {{ data.date | date }}
        </vs-col>
      </vs-row>
      <vs-row class="mb-2" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Korrekturzeit:</vs-col>
        <vs-col vs-w="2">
          <vs-input type="number" class="w-auto" v-model="data.hours" step="0.5"></vs-input>
        </vs-col>
      </vs-row>
      <vs-row class="mb-2" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Kommentar:</vs-col>
        <vs-col vs-w="9">
          <vs-input type="text" class="w-full" v-model="data.comment"></vs-input>
        </vs-col>
      </vs-row>

      <div class="flex justify-end mt-6">
        <vs-button color="danger" class="mr-auto" v-if="!isNew" @click="onClickDelete">Löschen</vs-button>
        <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
        <vs-button color="success" class="ml-4" @click="onClickSave">Speichern</vs-button>
      </div>
    </template>
  </vs-popup>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

export default {
  name: "CorrectionTime",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    correctionTime: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  data() {
    return {
      data: null,
    }
  },

  computed: {
    visible() {
      return this.active;
    },
    isNew() {
      if (!this.data) {
        return true;
      }

      return !this.data.id;
    },
    title() {
      return this.isNew ? 'Korrekturzeit hinzufügen' : 'Korrekturzeit bearbeiten';
    },
  },

  mounted() {
    if (this.active) {
      this.data = cloneDeep(this.correctionTime);
    }
  },

  methods: {
    onClickClose() {
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
      this.$emit('update:active', false)
    },

    onClickDelete() {
      this.$emit('delete', this.data);
    },

    onClickSave() {
      this.$emit('save', this.data);
    },
  },

  watch: {
    active(value) {
      if(value === true) {
        this.data = cloneDeep(this.correctionTime);
      }
    }
  },

  filters: {
    date(date) {
      return moment(date).format('DD.MM.YYYY');
    }
  }
}
</script>

<style scoped>

</style>