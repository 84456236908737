<template>
  <vx-card :title="`Jahresübersicht ${this.year}`" ref="card">

    <div v-if="!currentUserId || !year">
      Kein Nutzer/Jahr ausgewählt.
    </div>

    <div v-else-if="!fetched">
      Daten noch nicht geladen.
    </div>

    <template v-else>

      <div class="mb-6">
        <h5>Abwesenheitskontingente</h5>

        <div v-if="absenceQuotas.length > 0">
          <table class="w-full work-time-report-statistics-table">
            <tbody>
            <tr v-for="(quota, index) in absenceQuotas" :key="index">
              <td>{{ quota.name }}</td>
              <td>
                <span title="noch verfügbare Abwesenheit">{{ quota.days_remaining.toString().replaceAll(".", ",") }}</span>
                /
                <span title="insgesamt verfügbare Abwesenheit">{{ quota.days.toString().replaceAll(".", ",") }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-else>
          Keine Abwesenheits-Kontingente
        </div>
      </div>

      <div class="mb-6">
        <h5>Stundenübersicht</h5>

        <table class="w-full work-time-report-statistics-table">
          <tbody>
          <tr>
            <td class="pr-3">Jahressaldo</td>
            <td :class="{'text-danger': (balance < 0), 'text-success': (balance > 0)}">
              {{ balance.toString().replaceAll(".", ",") }} Std.
            </td>
          </tr>

          <tr>
            <td class="pr-3">Insgesamt anwesend</td>
            <td :class="{'text-danger': (presentHours < targetHours), 'text-success': (balance > targetHours)}" title="Alle täglichen Zeiteintragssaldos zusammengerechnet">
              {{ presentHours.toString().replaceAll(".", ",") }} Std.
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h5>Abwesenheiten</h5>

        <div v-if="absences.length > 0">
          <table class="w-full work-time-report-statistics-table">
            <tbody>
            <tr v-for="(absence, index) in absences" :key="index">
              <td class="pr-3">{{ absence.name }}</td>
              <td>
                {{ absence.daysCount.toString().replaceAll(".", ",") }}
                <span v-if="absence.daysCount === 1">Tag</span>
                <span v-else>Tage</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-else>
          Keine Abwesenheiten
        </div>
      </div>

    </template>

  </vx-card>
</template>

<script>
import ApiService from "@/api/index.js";

export default {
  name: "WorkTimeReportStatistics",

  data() {
    return {
      absenceQuotas: [],
      balance: 0,
      presentHours: 0,
      targetHours: 0,
      absences: [],
      fetched: false,
    }
  },

  computed: {
    currentUserId() {
      return this.$route.params.userId;
    },
    year() {
      return this.$route.params.year;
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      if (!this.currentUserId || !this.year) {
        this.absenceQuotas = [];
        this.balance = 0;
        this.presentHours = 0;
        this.targetHours = 0;
        this.absences = [];
        this.fetched = false;

        return false;
      }

      this.$refs.card.refreshcard();

      const dataPromise = ApiService.get(`time-sheet-overview/${this.year}?user_id=${this.currentUserId}`);

      Promise.all([dataPromise]).then(responses => {
        const data = responses[0].data.result;

        this.absenceQuotas = data.absence_quotas;
        this.balance = data.balance;
        this.presentHours = data.present_hours;
        this.targetHours = data.target_hours;
        this.absences = data.absences;

        this.$refs.card.removeRefreshAnimation();
        this.fetched = true;
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    }
  },

  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.work-time-report-statistics-table {
  @apply border border-2 mt-2 text-dark;
  border-color: #f8f8f8;
}

.work-time-report-statistics-table tr:nth-child(2n) {
  background-color: rgba(245, 245, 245,.2) !important;
}

.work-time-report-statistics-table tr td {
  @apply px-4 py-3;
}

.work-time-report-statistics-table tr td:first-child {
  @apply text-left;
}

.work-time-report-statistics-table tr td:last-child {
  @apply text-right
}
</style>