<template>
  <vs-popup id="time-stamp-modal" :title="title" :active="visible" @close="onClickClose" class="time-stamp-modal">
    <template>

      <vs-row class="mb-4">
        <b>Zeiteinträge UNIX Timestamps:</b>
      </vs-row>
      <vs-row class="mb-4">
        <vs-textarea placeholder="Zeiteinträge" v-model="data" class="w-full" rows="10" onkeyup="isValid = false"/>
        <span class="pseudo-link" @click="validateTimestamps()">Validate</span>
      </vs-row>

      <div class="flex justify-end mt-6">
        <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
        <vs-button color="success" class="ml-4" @click="onClickSave" :disabled="!isValid">Speichern</vs-button>
      </div>
    </template>
  </vs-popup>
</template>

<script>
import moment from 'moment';
import cloneDeep from "lodash/cloneDeep";
import ApiService from "@/api";

export default {
  name: "BulkTimeStamp",

  props: {
    active: {
      type: Boolean,
      default: false
    },
    timeStamp: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  data() {
    return {
      data: null,
      validateDateEntries: [],
      isValid: false
    }
  },

  mounted() {
    if (this.active) {
      this.data = cloneDeep(this.timeStamp);
    }
  },

  computed: {
    visible() {
      return this.active;
    },
    title() {
      return 'Bulk-Zeiten hinzufügen';
    },
  },
  methods: {
    onClickClose() {
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
      this.$emit('update:active', false)
    },

    onClickSave() {
      let dates = this.validateDateEntries

      dates.forEach(function (item) {
        ApiService.post('time-sheet-entry', item).then((response) => {
          if (response.status != 200) {
            this.$vs.notify({
              title: 'Fehler',
              text: data,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }).catch((error) => {
          this.$vs.notify({
            title: 'Fehler',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
      });


      this.closePopup()
    },
    validateTimestamps() {
      if (this.data.length < 5) {
        this.$vs.notify({
          title: 'Fehler',
          text: 'Eingabe fehlt',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        return
      }
      this.validateDateEntries = [];
      this.isValid = true;
      let splitLines = this.data.split("\n");
      for (var i = 0; i < splitLines.length; i++) {

        var date = new Date(splitLines[i] * 1000);

        if (!isNaN(parseFloat(date.getTime())) && isFinite(date.getTime()) && splitLines[i].length > 5) {

          this.validateDateEntries.push({
            time_sheet_id: this.timeStamp.time_sheet_id,
            timestamp: moment(date).format('YYYY-MM-DD HH:mm:ss')
          });

        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: 'Die Stempelzeit Zeile "' + i + '" ist nicht valide:' + splitLines[i],
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
          this.isValid = false;
        }
      }
      if (this.isValid === true) {
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Die Validierung von ' + splitLines.length + ' Zeilen war erfolgreich',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      }
    }
  }

}
</script>

<style scoped>

</style>