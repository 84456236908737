<template>
  <vs-popup :title="title" :active="visible" @close="onClickClose" class="absence-modal">
    <template v-if="data">

      <template v-if="userHasPermission('can_manage_absence_requests') || isNew">

        <vs-row class="mb-4" vs-align="center" v-if="userSelection">
          <vs-col vs-w="3" class="font-bold">Benutzer:</vs-col>
          <vs-col vs-w="9">
            <vs-select v-model="data.user_id" class="w-full" placeholder="Benutzer wählen">
              <vs-select-item :key="item.id" :value="item.id"
                              :text="`${item.detail.first_name} ${item.detail.last_name}`" v-for="item in users"/>
            </vs-select>
          </vs-col>
        </vs-row>


        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Ganztägig:</vs-col>
          <vs-col vs-w="2">
            <vs-checkbox v-model="data.whole_day" @change="onChangeWholeDay"></vs-checkbox>
          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Datum:</vs-col>
          <vs-col vs-w="4">
            <flat-pickr v-model="data.starts_at"
                        :key="startPickerKey"
                        :config="flatPickrConfig.start"
                        @on-change="onChangeStartAt"
                        class="w-full">
            </flat-pickr>
          </vs-col>
          <vs-col vs-w="1" class="text-center">bis</vs-col>
          <vs-col vs-w="4">
            <flat-pickr v-model="data.ends_at"
                        :key="endPickerKey"
                        :config="flatPickrConfig.end"
                        class="w-full">
            </flat-pickr>
          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Abszenz Typ:</vs-col>
          <vs-col vs-w="9">

            <vs-select v-model="data.absence_type_id" class="w-full" placeholder="Abszenz Typ wählen">
              <vs-select-item :key="item.id" :value="item.id" :text="item.name" v-for="item in absenceTypes"/>
            </vs-select>

          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center" v-if="absenceType && absenceType.affect_absence_quotas">
          <template v-if="availableAbsenceQuotas && availableAbsenceQuotas.length > 0">
            <vs-col vs-w="3" class="font-bold">Kontingent:</vs-col>
            <vs-col vs-w="9">
              <vs-select v-model="data.absence_quota_id" class="w-full" placeholder="Kontingent wählen">
                <vs-select-item :key="item.id" :value="item.id" :text="`${item.name} (${getPeriodAvailableDays(item)} von ${item.days} Tage/n verfügbar)`"
                                v-for="item in availableAbsenceQuotas"/>
              </vs-select>
            </vs-col>
          </template>
          <template v-else>
            <vs-alert class="mb-2 mt-2" color="danger">Kein Abwesenheitskontingent verfügbar</vs-alert>
          </template>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Kommentar:</vs-col>
          <vs-col vs-w="9">
            <vs-input type="text" class="w-auto" v-model="data.comment"></vs-input>
          </vs-col>
        </vs-row>

        <template v-if="userHasPermission('can_manage_absence_requests')">
          <vs-row class="mb-4" vs-align="center">
            <vs-col vs-w="3" class="font-bold">Status:</vs-col>
            <vs-col vs-w="9">
              <vs-select v-model="data.status" class="w-full">
                <vs-select-item :key="item.value" :value="item.value" :text="item.label" v-for="item in statusOptions"/>
              </vs-select>
            </vs-col>
          </vs-row>



          <vs-row class="mb-4" vs-align="center">
            <vs-col vs-w="3" class="font-bold">Begründung Vorgesetzter:</vs-col>
            <vs-col vs-w="9">
              <vs-input type="text" class="w-auto" v-model="data.rejection_reason"></vs-input>
            </vs-col>
          </vs-row>
        </template>
      </template>

      <template v-else>
        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Ganztägig:</vs-col>
          <vs-col vs-w="2">
            <vs-icon icon="check_circle" class="check_circle" v-if="data.whole_day"></vs-icon>
            <vs-icon icon="cancel" class="cancel" v-else></vs-icon>
          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Datum:</vs-col>
          <vs-col vs-w="4">
            {{ data.starts_at|date(!data.whole_day) }}
          </vs-col>
          <vs-col vs-w="1" class="text-center">bis</vs-col>
          <vs-col vs-w="4">
            {{ data.ends_at|date(!data.whole_day) }}
          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
          <vs-col vs-w="3" class="font-bold">Abszenz Typ:</vs-col>
          <vs-col vs-w="9">
            {{ data.absence_type.name }}
          </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center" v-if="data.absence_type && data.absence_type.affect_absence_quotas">
          <template v-if="data.absence_quota">
            <vs-col vs-w="3" class="font-bold">Kontingent:</vs-col>
            <vs-col vs-w="9">
              {{ data.absence_quota.name }} ({{ data.absence_quota.days }} Tage)
            </vs-col>
          </template>

        </vs-row>

        <template v-if="!isNew">
          <vs-row class="mb-4" vs-align="center">
            <vs-col vs-w="3" class="font-bold">Status:</vs-col>
            <vs-col vs-w="9">
              {{ status.label }}
            </vs-col>
          </vs-row>

          <vs-row class="mb-4" vs-align="center" v-if="data.comment">
            <vs-col vs-w="3" class="font-bold">Kommentar:</vs-col>
            <vs-col vs-w="9">
              {{ data.comment }}
            </vs-col>
          </vs-row>

          <vs-row class="mb-4" vs-align="center" v-if="data.rejection_reason">
            <vs-col vs-w="3" class="font-bold">Begründung Vorgesetzter:</vs-col>
            <vs-col vs-w="9">
              {{ data.rejection_reason }}
            </vs-col>
          </vs-row>
        </template>
      </template>

      <template v-if="userHasPermission('can_manage_absence_requests') || isNew">
          <div v-if="data.absence_type_id == 6">
              Bitte denk dran, eine Korrekturzeit vorzunehmen bei dem Absenz-Typ == Abbau Projektierte Überarbeit
          </div>
        <div class="flex justify-end mt-6">
          <vs-button color="danger" class="mr-auto" v-if="!isNew" @click="onClickDelete">Löschen</vs-button>
          <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
          <vs-button color="success" class="ml-4" @click="onClickSave" :disabled="saveIsDisabled">Speichern</vs-button>
        </div>
      </template>

      <template v-else>
        <div class="flex justify-end mt-6">
          <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
        </div>
      </template>

    </template>
  </vs-popup>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component';
import {German} from 'flatpickr/dist/l10n/de.js';
import AbsenceStatusOptions from "../absence-status-options";
import {mapGetters} from "vuex";

export default {
  name: "Absence",
  components: {
    'v-select': vSelect,
    flatPickr
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    absence: {
      type: Object,
      default: () => {
        return null;
      }
    },
    absenceQuotas: {
      type: Array,
      default: () => {
        return [];
      }
    },
    absenceTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userSelection: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },

  data() {
    return {
      data: null,
      startPickerKey: 1,
      endPickerKey: 1,
      statusOptions: AbsenceStatusOptions,
      flatPickrConfig: {
        end: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d H:i:S',
          altInput: true,
          time_24hr: true,
          allowInput: true,
          enableTime: false,
          locale: German,
        },
        start: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d H:i:S',
          altInput: true,
          time_24hr: true,
          allowInput: true,
          enableTime: false,
          locale: German,
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userHasPermission']),

    visible() {
      return this.active;
    },
    isNew() {
      if (!this.data) {
        return true;
      }

      return !this.data.id;
    },
    title() {
      return this.isNew ? 'Abszenz hinzufügen' : 'Abszenz bearbeiten';
    },
    availableAbsenceQuotas() {
      if (!this.data || !this.data.starts_at) {
        return null;
      }

      let absenceQuotas = this.absenceQuotas;

      if (this.userSelection) {
        const user = this.users.find(user => user.id === this.data.user_id);

        if (!this.data.user_id || !user || !user.absence_quotas) {
          return null;
        }

        absenceQuotas = user.absence_quotas;
      }



      if (!this.data.ends_at) {
        return absenceQuotas.filter(quota => {

          if (!quota.ends_at) {
            return moment(this.data.starts_at).subtract('1','day').isSameOrAfter(moment(quota.starts_at));
          }

          return moment(this.data.starts_at).isBetween(moment(quota.starts_at).subtract('1','day'), moment(quota.ends_at).add('1','day'));
        });
      }

      return absenceQuotas.filter(quota => {
        if (!quota.ends_at) {
          return moment(this.data.ends_at).subtract('1','day').isSameOrAfter(moment(quota.starts_at));
        }

        // isBetween oder gleich
        return moment(this.data.starts_at).isBetween(moment(quota.starts_at).subtract('1','day'), moment(quota.ends_at).add('1','day')) &&
            moment(this.data.ends_at).isBetween(moment(quota.starts_at).subtract('1','day'), moment(quota.ends_at).add('1','day'));
      });
    },
    absenceType() {
      if (!this.data || !this.data.absence_type_id) {
        return null;
      }

      return this.absenceTypes.find(type => type.id === this.data.absence_type_id);
    },

    status() {
      if (!this.data || !this.data.status) {
        return null;
      }

      return this.statusOptions.find(type => type.value === this.data.status);
    },
    saveIsDisabled(){
      if (!this.data || !this.data.absence_type_id) {
        return true;
      }

      let selectedAbsenceType =  this.absenceTypes.find(type => type.id === this.data.absence_type_id);

      if (selectedAbsenceType && selectedAbsenceType.affect_absence_quotas === 1) {
        if(!this.data || !this.data.absence_quota_id) {
          return true;
        }
      }
      return false;
    },
  },

  mounted() {
    if (this.active) {
      this.data = cloneDeep(this.absence);
      this.updateMinDate();
      this.updateDatePickers();
    }
  },

  methods: {
    onClickClose() {
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
      this.$emit('update:active', false)
    },

    onClickDelete() {
      this.$emit('delete', this.data);
    },

    onClickSave() {
      this.$emit('save', this.data);
    },

    onChangeWholeDay() {
      this.updateDatePickers();
    },

    onChangeStartAt() {
      this.updateMinDate();
    },

    isInt(value) {
      return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
    },
    updateMinDate() {
      this.flatPickrConfig.end.minDate = moment(this.data.starts_at).format('YYYY-MM-DD HH:II');
      this.flatPickrConfig.end.maxDate = moment(this.data.starts_at).endOf('year').format('YYYY-MM-DD HH:II');
      this.endPickerKey = Math.random();
    },

    updateDatePickers() {
      this.flatPickrConfig.end.enableTime = !this.data.whole_day;
      this.flatPickrConfig.start.enableTime = !this.data.whole_day;

      const altFormat = this.data.whole_day ? 'd.m.Y' : 'd.m.Y  H:i';

      this.flatPickrConfig.start.altFormat = altFormat;
      this.flatPickrConfig.end.altFormat = altFormat;

      this.startPickerKey = Math.random();
      this.endPickerKey = Math.random();
    },

    getPeriodAvailableDays(absenceQuota) {
      let year = moment().format('YYYY');

      if(this.data.starts_at) {
        year = moment(this.data.starts_at).format('YYYY');
      }

      if(!absenceQuota.period_available || !absenceQuota.period_available[year]) {
        return absenceQuota.days;
      }

      return absenceQuota.period_available[year];
    }
  },

  watch: {
    active(value) {
      if (value === true) {
        this.data = cloneDeep(this.absence);
        this.updateMinDate();
        this.updateDatePickers();
      }
    }
  },

  filters: {
    date(date, time = false) {
      if (!date || !moment(date).isValid()) {
        return null;
      }

      if (!time) {
        return moment(date).format('DD.MM.YYYY');
      }

      return moment(date).format('DD.MM.YYYY HH:mm');
    }
  },

}
</script>

<style scoped>

</style>