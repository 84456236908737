<template>
  <vs-popup id="time-stamp-modal" :title="title" :active="visible" @close="onClickClose" class="time-stamp-modal">
    <template v-if="data">

      <vs-row class="mb-4">
        <vs-col class="pr-4" style="width: 80px; align-self: center">
          <b>Datum:</b>
        </vs-col>
        <vs-col class="pr-4" style="width: auto; align-self: center">
          {{ data.timestamp | date }}
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="pr-4" style="width: 80px;  align-self: center">
          <b>Uhrzeit:</b>
        </vs-col>
        <vs-col vs-w="2" class="pr-2">
          <vs-input placeholder="HH" v-model="hour" class="w-full" @keyup.enter="onEnter"/>
        </vs-col>
        <vs-col style="width: 10px; align-self: center">
          :
        </vs-col>
        <vs-col vs-w="2" class="pr-4">
          <vs-input placeholder="MM" v-model="minute" class="w-full" @keyup.enter="onEnter"/>
        </vs-col>
        <vs-col style="width: auto; align-self: center">
          Uhr
        </vs-col>
      </vs-row>

      <div class="flex justify-end mt-6">
        <vs-button color="danger" class="mr-auto" v-if="!isNew" @click="onClickDelete">Löschen</vs-button>
        <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
        <vs-button color="success" class="ml-4" @click="onClickSave">Speichern</vs-button>
      </div>
    </template>
  </vs-popup>
</template>

<script>
import moment from 'moment';
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "TimeStamp",

  props: {
    active: {
      type: Boolean,
      default: false
    },
    timeStamp: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  data() {
    return {
      data: null,
    }
  },

  mounted() {
    if (this.active) {
      this.data = cloneDeep(this.timeStamp);
    }
  },

  computed: {
    visible() {
      return this.active;
    },
    isNew() {
      if(!this.data) {
        return true;
      }

      return !this.data.id;
    },
    title() {
      return this.isNew ? 'Zeit hinzufügen' : 'Zeit bearbeiten';
    },
    hour: {
      set(value) {
        if(isNaN(value)) {
          value = 1;
        }

        if(value > 23) {
          value = 23;
        }

        const timestamp = moment(this.data.timestamp);
        timestamp.set({'hour': value});

        this.data.timestamp = timestamp.format('YYYY-MM-DD HH:mm:ss');
      },
      get() {
        return moment(this.data.timestamp).format('HH');
      }
    },
    minute: {
      set(value) {
        if(isNaN(value)) {
          value = 0;
        }

        if(value > 59) {
          value = 59;
        }

        const timestamp = moment(this.data.timestamp);
        timestamp.set({'minute': value});

        this.data.timestamp = timestamp.format('YYYY-MM-DD HH:mm:ss');
      },
      get() {
        return moment(this.data.timestamp).format('mm');
      }
    }
  },

  methods: {
    onClickClose() {
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
      this.$emit('update:active', false)
    },

    onClickDelete() {
      this.$emit('delete', this.data);
    },

    onClickSave() {
      this.$emit('save', this.data);
    },
    onEnter() {
      this.onClickSave();
    }
  },

  watch: {
    active(value) {
      if(value === true) {
          this.data = cloneDeep(this.timeStamp);
      }
    }
  },

  filters: {
    date(date) {
      return moment(date).format('DD.MM.YYYY');
    }
  }

}
</script>

<style scoped>

</style>