<template>
  <div class="vx-row">

    <div class="vx-col md:w-3/12 w-full mb-1">
      <work-time-report-users class="mb-8"
                              v-if="userHasPermission('can_manage_all_timesheets')"></work-time-report-users>
      <work-time-report-statistics></work-time-report-statistics>
    </div>

    <div class="vx-col md:w-9/12 w-full mb-1">
      <work-time-employee-content v-if="fetched" :holidays="holidays" :timeSheets="timeSheets" @refresh="onRefresh">
      </work-time-employee-content>
    </div>
  </div>
</template>

<script>
import ApiService from "../../api/index";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import WorkTimeReportUsers from "./components/WorkTimeReportUsers";
import WorkTimeEmployeeContent from "./components/WorkTimeReportContent";
import WorkTimeReportStatistics from "./components/WorkTimeReportStatistics";
import {mapGetters} from "vuex";
import moment from "moment";

import Store from '../../store/store';
import Vue from "vue";

export default {
  name: "WorkingTimeReport",
  components: {
    WorkTimeReportStatistics,
    WorkTimeEmployeeContent,
    WorkTimeReportUsers,
    flatPickr,
  },

  data() {
    return {
      timeSheetId: null,
      timeSheets: [],
      fetched: false,
      datePickerConfig: {},
      year: null,
      month: null,
      holidays: []
    }
  },

  computed: {
    ...mapGetters(['userHasPermission']),
  },

  beforeCreate() {
    if (this.$store.getters.userHasPermission('can_manage_all_timesheets')) {
      return;
    }

    if (this.$route.name !== 'WorkingTimeReportUserYearMonth' || this.$route.params.userId !== this.$store.getters.userId) {
      this.$router.push({
        name: 'WorkingTimeReportUserYearMonth', params: {
          userId: this.$store.getters.userId,
          year: moment().format('YYYY'),
          month: moment().format('MM')
        }
      });
    }
  },

  async created() {
    let date = new Date();
    this.month = this.$route.params.month || date.getMonth();
    this.year = this.$route.params.year || date.getFullYear();

    this.$vs.loading();
    await this.fetchData()

    this.fetchTimeSheet(this.$route.params.userId)

    this.$vs.loading.close()
  },

  methods: {
    fetchData() {
      const calendarPromise = ApiService.get('calendar-holidays');

      Promise.all([calendarPromise]).then(responses => {
        this.holidays = responses[0].data.result;
      });
    },

    onRefresh() {
      this.fetchTimeSheet(this.$route.params.userId);
    },

    fetchTimeSheet(userId) {
      if (!userId) {
        return;
      }

      const month = this.$route.params.month;
      const year = this.$route.params.year;

      this.$vs.loading();

      ApiService.get(`time-sheet` +
          `?filter[0][field]=user_id` +
          `&filter[0][expression]=exact` +
          `&filter[0][value]=${userId}`).then(response => {

        this.timeSheets = response.data.result;
        this.timeSheetId = null;

        const currentTimeSheet = this.timeSheets.find(timeSheet => {
          return timeSheet.month === month && timeSheet.year === year;
        });

        if (currentTimeSheet) {
          this.timeSheetId = currentTimeSheet.id;
        }

        this.fetched = true;
        this.$vs.loading.close()
      });
    }

  },

  watch: {
    $route(to) {
      this.fetchTimeSheet(to.params.userId)
    }
  }
}
</script>
