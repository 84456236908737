<template>
  <vx-card title="Mitarbeiter" ref="card">
    <div class="vx-row mb-6 mt-0">
      <div class="vx-col w-full">
        <vx-input-group class="mb-0">
          <vs-input v-model="searchTerm" placeholder="Suchen ..."></vs-input>
        </vx-input-group>
      </div>
    </div>

    <VuePerfectScrollbar style="max-height: 320px">
      <vs-list>
        <vs-list-item v-for="user in filteredUsers"
                      :title="user.detail.first_name + ' ' + user.detail.last_name "
                      :subtitle="user.email">
          <vs-button color="primary" size="small" :disabled="isSelected(user)" @click="selectUser(user)">Auswählen
          </vs-button>
        </vs-list-item>
      </vs-list>
    </VuePerfectScrollbar>

  </vx-card>
</template>

<script>
import ApiService from "../../../api";
import moment from "moment";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: "WorkTimeReportUsers",

  components: {
    VuePerfectScrollbar
  },

  data() {
    return {
      users: [],
      searchTerm: null,
      isLoading: false
    }
  },

  computed: {
    filteredUsers() {
      if (!this.searchTerm) {
        return this.users;
      }

      const term = this.searchTerm.toLowerCase();

      return this.users.filter(user => {
        return user.detail.first_name.toLowerCase().includes(term) || user.detail.last_name.toLowerCase().includes(term);
      });
    }
  },

  created() {
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData: function () {
      this.$refs.card.refreshcard();

      return ApiService.get('users').then(response => {
        this.users = response.data.result;
        this.$refs.card.removeRefreshAnimation();
      });
    },

    selectUser(user) {
      this.$router.push({
        name: 'WorkingTimeReportUserYearMonth', params: {
          userId: user.id,
          year: moment().format('YYYY'),
          month: moment().format('MM')
        }
      });
    },

    isSelected(user) {
      if (!this.$route.params.userId) {
        return false;
      }

      return parseInt(user.id) === parseInt(this.$route.params.userId);
    }
  },


}
</script>

<style scoped>

</style>