<template>
  <vx-card title="Stempelzeiten" ref="card">

    <vs-alert :active="!timeSheets">
      Kein Mitarbeiter ausgewählt
    </vs-alert>

    <template v-if="timeSheets">

      <div class="menu-bar mb-3">
        <div class="menu-bar--list">
          <template v-for="year in years">
            <div class="menu-bar--list-item"
                 :class="{'active': year === currentYear}"
                 @click="onChangeYear(year)">
              {{ year }}
            </div>
          </template>

          <div class="menu-bar--list-item menu-bar--list-item-dark" @click="addTimeSheetsPrompt=true">
            Hinzufügen
          </div>
        </div>
      </div>


      <div class="menu-bar mb-8 shadow" v-show="months && months.length > 0">
        <div class="menu-bar--list stretch">
          <template v-for="month in months">
            <div class="menu-bar--list-item"
                 :class="{'active': month.month === currentMonth}"
                 @click.prevent="onChangeMonth(month)">
              {{ month.name }}
            </div>
          </template>
        </div>
      </div>

    </template>

    <template v-if="dates.length > 0 && fetched" v-show="!hideDates">
      <vs-table :data="dates" class="bordered work-time-table" hoverFlat>
        <template slot="header">
          <h3 class="pb-4">
            Monat: {{ currentDate|month }} {{ currentDate|year }}
          </h3>
        </template>

        <template slot="thead">
          <vs-th colspan="3">Datum</vs-th>
          <vs-th>Zeiten</vs-th>
          <vs-th class="text-center" style="width: 80px">Std.</vs-th>
          <vs-th class="text-center" style="width: 80px">Saldo</vs-th>
          <vs-th>Abszenz</vs-th>
          <vs-th style="width: 100px">Aktionen</vs-th>
        </template>

        <template slot-scope="{data}">
          <template v-for="(date, index) in data">

            <vs-tr :state="getTableRowState(date.date)" class="text-dark work-time-table-row">
              <vs-td>
                {{ date.date|date }}
              </vs-td>
              <vs-td style="width: 50px" class="text-center">
                {{ date.date|day }}
              </vs-td>
              <vs-td style="width: 50px" class="text-center">
                <div v-if="date.entry">

                  <div class="flex user-activity-location-icons-container">
                    <vs-icon
                        icon-pack="material-icons"
                        icon="apartment"
                        size="small"
                        title="Büro Esslingen"
                        @click="applyActivityLocation('apartment',date.date)"
                        :class="{'text-success': isLocation('apartment', date.entry.activity_location),'text-muted': !isLocation('apartment', date.entry.activity_location)}"
                    />
                    <vs-icon
                        icon-pack="material-icons"
                        icon="home"
                        size="small"
                        title="Homeoffice"
                        @click="applyActivityLocation('home',date.date)"
                        :class="{'text-success': isLocation('home', date.entry.activity_location),'text-muted': !isLocation('home', date.entry.activity_location)}"
                    />

                    <vs-icon
                        icon-pack="material-icons"
                        icon="close"
                        size="small"
                        title="Löschen"
                        v-if="date.entry.activity_location !== null"
                        @click="deleteActivityLocation(date.date)"
                        class="text-muted delete-activity-location"
                    />
                  </div>

                </div>
              </vs-td>
              <vs-td>
                <template v-if="!isHoliday(date.date)">

                  <template v-if="date.entry">
                    <template v-if="date.entry.entries" v-for="(entry, index) in date.entry.entries">
                      <span v-if="canEditTimeStamp(date.date)"
                            class="text-pseudo-link"
                            @click="onClickEditTimeStamp(entry)">{{ entry.timestamp | time }}</span>
                      <span v-else
                            @click="onClickEditTimeStamp(entry)">{{ entry.timestamp | time }}</span>

                      <span v-if="canAddTimeStamp(date.date) || (index + 1) < date.entry.entries.length"> - </span>
                    </template>
                  </template>

                  <span class="pseudo-link add-time-stamp-link" v-if="canAddTimeStamp(date.date)"
                        @click="onClickCreateTimeStamp(date.date)">Zeit hinzufügen</span>

                </template>

                <template v-else>
                  {{ getHolidayForDate(date.date).name }}
                </template>
              </vs-td>

              <vs-td class="text-center">
                <template v-if="date.entry && (isWorkingDay(date.date) || date.entry.entries)">
                  <span :class="{'text-danger': date.entry.balance < 0}" :title="'Real hours worked | Soll: '+date.entry.target_hours + '| Corrected hours: '+date.entry.corrected_hours">
                    {{ date.entry.net_worked_hours | number }}
                  </span>
                </template>

                <template v-if="date.entry && date.entry.pause_minutes < date.entry.target_pause_minutes">
                  <vx-tooltip :text="`${date.entry.target_pause_minutes} Minuten Pause wurden berechnet.`"
                              class="ml-1 inline-block">
                    <vs-icon icon="timer" class="text-warning"></vs-icon>
                  </vx-tooltip>
                </template>

              </vs-td>

              <vs-td class="text-center">
                <template v-if="date.entry &&  (isWorkingDay(date.date) || date.entry.entries)">
                    <span :class="{'text-danger': date.entry.balance < 0}">
                    {{ date.entry.balance | number }}
                  </span>
                </template>
              </vs-td>

              <vs-td>

                <template v-if="date.entry && isWorkingDay(date.date)">
                  <template v-if="date.entry.absence">
                    <span class="text-pseudo-link" @click="onClickEditAbsence(date.entry.absence)">
                          {{ date.entry.absence.absence_type.name }}:
                          {{ date.entry.absence_factor | number }}
                    </span>
                  </template>
                  <template v-else-if="canAddAbsence(date.date)">
                    <vx-tooltip text="Abszenz hinzufügen" class="w-1" position="left">
                      <vs-button color="success" type="filled" icon="eventbusy" size="small" class="inline-block"
                                 @click="onClickCreateAbsence(date.date)">
                      </vs-button>
                    </vx-tooltip>
                  </template>
                </template>

                <template v-else-if="isWorkingDay(date.date) && canAddAbsence(date.date)">
                  <vx-tooltip text="Abszenz hinzufügen" class="w-1" position="left">
                    <vs-button color="success" type="filled" icon="eventbusy" size="small" class="inline-block"
                               @click="onClickCreateAbsence(date.date)">
                    </vs-button>
                  </vx-tooltip>
                </template>

              </vs-td>

              <vs-td>
                <div class="flex justify-end">
                  <template
                      v-if="date.entry && !date.entry.correction_time && isWorkingDay(date.date) && canAddCorrectionTime(date.date)">
                    <vx-tooltip text="Korrekturzeit hinzufügen" position="left">
                      <vs-button color="success" type="filled" icon="history" size="small" class="inline-block"
                                 @click="onClickCreateCorrectionTime(date.date)">
                      </vs-button>
                    </vx-tooltip>
                  </template>

                </div>
              </vs-td>

            </vs-tr>

            <template v-if="date.entry && date.entry.correction_time && isWorkingDay(date.date)">
              <vs-tr state="light" class="text-dark">
                <vs-td colspan="6">
                  Korrekturzeit:
                  <span v-show="date.entry.correction_time.comment">"{{ date.entry.correction_time.comment }}"</span>
                  <span :class="[{'text-danger': date.entry.correction_time.hours < 0},
                      {'text-success font-bold': date.entry.correction_time.hours >= 0}]">
                    {{ date.entry.correction_time.hours | number }} Std.
                  </span>
                </vs-td>
                <vs-td>
                  <div class="flex justify-end" v-if="userHasPermission('can_manage_correction_times')">
                    <vx-tooltip text="Korrekturzeit bearbeiten" class="w-auto inline-block" position="left">
                      <vs-button color="dark" type="filled" icon="edit" size="small"
                                 @click="onClickEditCorrectionTime(date.entry.correction_time)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Korrekturzeit löschen" class="inline-block" position="left">
                      <vs-button color="danger" type="filled" icon="delete" size="small"
                                 @click="onDeleteCorrectionTime(date.entry.correction_time)"></vs-button>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>

          </template>

          <vs-tr :state="(timeSheet.corrected_balance) >= 0 ? 'success':'danger'"
                 class="timesheet-total-row">
            <vs-td colspan="3"></vs-td>
            <vs-td>Sollstunden: {{ timeSheet.target_hours | number }} Std.<br>
            </vs-td>
            <vs-td class="text-center">
              <span title="Ist-Stunden (Tatsächlich gearbeitete Stunden)" v-if="timeSheet.net_worked_hours"><br>{{ timeSheet.net_worked_hours | number }} Std.</span><br>
              <small title="Ist-Stunden incl. Absencstunden (Corrected Hours)" style="color: #aaaaaa">{{ (timeSheet.corrected_hours) | number }}</small>

            </vs-td>
            <vs-td class="text-center">{{ (timeSheet.corrected_balance) | number }} Std.</vs-td>
            <vs-td colspan="2">{{ timeSheet.absence_days| number }} ({{ timeSheet.absence_hours | number }} Std.)</vs-td>
          </vs-tr>
        </template>

      </vs-table>

      <div class="mt-10">
        <span @click="onClickBulkCreateTimeStamp()"
              class="pseudo-link add-time-stamp-link">Bulk-Zeiteinträge hinzufügen</span>
      </div>
    </template>


    <time-stamp :time-stamp="currentTimeStamp"
                :active.sync="timeStampPopupActive"
                @delete="onDeleteTimeStamp"
                @save="onSaveTimeStamp">
    </time-stamp>

    <bulk-time-stamp :time-stamp="currentTimeStamp"
                     :active.sync="bulkTimeStampPopupActive">
    </bulk-time-stamp>

    <correction-time
        :correction-time="currentCorrectionTime"
        :active.sync="correctionTimePopupActive"
        @delete="onDeleteCorrectionTime"
        @save="onSaveCorrectionTime">
    </correction-time>

    <absence
        :absence="currentAbsence"
        :active.sync="absencePopupActive"
        :absence-quotas="absenceQuotas"
        :absence-types="absenceTypes"
        @delete="onDeleteAbsence"
        @save="onSaveAbsence">
    </absence>

    <vs-prompt
        @accept="onAddTimeSheets"
        title="Stempelzeit Tabellen anlegen"
        @close="addTimeSheetsPrompt=false"
        acceptText="Anlegen"
        cancelText="Abbrechen"
        :active.sync="addTimeSheetsPrompt">
      <p>Sind Sie sicher dass das die Stempelzeit Tabellen für das Jahr <b>{{ nextTimeSheetYear }}</b> angelegt werden
        sollen?</p>
    </vs-prompt>

  </vx-card>


</template>

<script>
import ApiService from "../../../api";
import moment from "moment";
import TimeStamp from "./TimeStamp";
import BulkTimeStamp from "./BulkTimeStamp";
import ModelFactory from "../../../mixins/model/model-factory";
import CorrectionTime from "./CorrectionTime";
import Absence from "./Absence";
import cloneDeep from "lodash/cloneDeep";
import {mapGetters} from "vuex";

export default {
  name: "WorkTimeEmployeeContent",
  components: {Absence, CorrectionTime, TimeStamp, BulkTimeStamp},
  props: {
    timeSheets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    holidays: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      timeSheet: null,
      absenceQuotas: [],
      absenceTypes: [],
      fetched: false,

      currentTimeStamp: null,
      timeStampPopupActive: false,

      bulkTimeStampPopupActive: false,

      currentCorrectionTime: null,
      correctionTimePopupActive: false,

      currentAbsence: null,
      absencePopupActive: false,

      addTimeSheetsPrompt: false,

      hideDates: false,
    }
  },

  computed: {
    ...mapGetters(['userHasPermission']),

    dates() {
      const year = this.$route.params.year,
          month = this.$route.params.month;

      if (!this.timeSheet || !this.timeSheets || !this.timeSheets.length) {
        return [];
      }

      let dates = [];

      if (!year || !month) {
        return dates;
      }

      let daysInMonth = parseInt(moment(`${year}-${month}-01`).daysInMonth());

      while (daysInMonth > 0) {
        dates.push(moment(`${year}-${month}-${daysInMonth}`));
        daysInMonth--;
      }

      dates = dates.sort((a, b) => {
        return a.format('YYYYMMDD') - b.format('YYYYMMDD');
      })

      const result = [];

      dates.forEach(date => {
        result.push({
          date: date,
          entry: this.getDateForDay(date)
        });
      })

      return result;
    },

    currentDate() {
      const year = this.$route.params.year,
          month = this.$route.params.month;

      if (!year || !month) {
        return moment();
      }

      return moment(`${year}-${month}-01`);
    },

    currentYear() {
      return this.$route.params.year;
    },

    currentMonth() {
      return this.$route.params.month;
    },

    currentUserId() {
      return this.$route.params.userId;
    },

    years() {
      return this.timeSheets.map(timeSheet => {
        return timeSheet.year;
      }, []).filter((value, index, self) => self.indexOf(value) === index);
    },

    months() {
      return this.timeSheets.filter(timeSheet => {
        return timeSheet.year === this.currentYear;
      }).map(timeSheet => {
        const date = moment(`${this.currentYear}-${timeSheet.month}-01`);
        return {
          'name': date.format('MMMM'),
          'month': timeSheet.month
        };

      }, []);
    },

    timeSheetId() {
      const year = this.$route.params.year,
          month = this.$route.params.month;

      if (!year || !month) {
        return null;
      }

      const currentTimeSheet = this.timeSheets.find(timeSheet => {
        return timeSheet.month === month && timeSheet.year === year;
      });

      if (!currentTimeSheet) {
        return null;
      }

      return currentTimeSheet.id;
    },

    nextTimeSheetYear() {
      if (!this.timeSheets || this.timeSheets.length < 1) {
        return moment().format('YYYY');
      }

      const years = this.timeSheets.map(item => {
        return parseFloat(item.year);
      });

      return moment().set({year: Math.max(...years)}).add(1, 'year').format('YYYY');
    },

  },

  created() {
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onAddTimeSheets() {
      const year = this.nextTimeSheetYear;
      const collection = [];

      for (let i = 1; i <= 12; i++) {
        collection.push({
          user_id: this.currentUserId,
          month: ('0' + i).slice(-2),
          year: year
        })
      }

      this.$vs.loading();

      ApiService.post('time-sheet', {collection: collection}).then(response => {
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Die Stempelzeit Tabellen wurden erstellt.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        this.$vs.loading.close();
        this.hideDates = false;

        this.$emit('refresh');
      }).catch(error => {
        this.$vs.notify({
          title: 'Fehler',
          text: 'Die Stempelzeit Tabellen konnte nicht erstellt werden',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        this.hideDates = true;
        this.$vs.loading.close();
        this.$emit('refresh');
      });

    },
    onChangeYear(year) {
      const currentYear = this.$route.params.year;

      if (year === currentYear) {
        return;
      }

      this.$router.push({
        name: 'WorkingTimeReportUserYearMonth', params: {
          userId: this.$route.params.userId,
          year: year,
          month: '01'
        }
      });
    },

    onChangeMonth(month) {
      const currentMonth = this.$route.params.month;

      if (month.month === currentMonth) {
        return;
      }

      this.$router.push({
        name: 'WorkingTimeReportUserYearMonth', params: {
          userId: this.$route.params.userId,
          year: this.$route.params.year,
          month: month.month
        }
      });
    },

    fetchData: function () {
      this.timeSheet = null;

      if (!this.timeSheetId) {
        this.$refs.card.removeRefreshAnimation();
        return;
      }

      this.$refs.card.refreshcard();

      const timeSheetPromise = ApiService.get(`time-sheet/${this.timeSheetId}`);
      const absenceTypePromise = ApiService.get(`absence-type`);
      const absenceQuotasPromise = ApiService.get(`absence-quota?filter[user_id]=${this.currentUserId}&appends=period_available`);

      Promise.all([timeSheetPromise, absenceTypePromise, absenceQuotasPromise]).then(responses => {
        this.timeSheet = responses[0].data.result;
        this.absenceTypes = responses[1].data.result;
        this.absenceQuotas = responses[2].data.result;

        this.$refs.card.removeRefreshAnimation();
        this.fetched = true;
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });

    },

    getDateForDay(date) {
      if (!this.timeSheet) {
        return null;
      }

      const result = this.timeSheet.date_entries[moment(date).format('YYYY-MM-DD')];

      if (result) {
        return cloneDeep(result)
      }

      return null;
    },
    isWorkingDay(date) {
      const timeSheetSetting = this.getTimeSheetSettingForDate(date);

      if (!timeSheetSetting) {
        return false;
      }

      const dayName = moment(date).locale("en").format('ddd').toLowerCase(),
          targetHours = timeSheetSetting[dayName];

      return targetHours !== null && parseFloat(targetHours) > 0;
    },

    getTimeSheetSettingForDate(date) {
      if (!this.timeSheet || !this.timeSheet.time_sheet_setting) {
        return null;
      }

      let settings = this.timeSheet.time_sheet_setting;

      if (!Array.isArray(settings) && typeof settings === 'object') {
        settings = Object.values(settings);
      }

      for (let timeSheetSetting of settings) {
        if (moment(date).diff(moment(timeSheetSetting.starts_at), 'days') >= 0) {
          return timeSheetSetting;
        }
      }
    },

    isHoliday(date) {
      const holiday = this.getHolidayForDate(date);
      return holiday !== null && holiday !== undefined;
    },

    isToday(date) {
      return moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
    },

    getHolidayForDate(date) {
      return this.holidays.find(holiday => {
        return holiday.date === moment(date).format('YYYY-MM-DD') && holiday.regions.bw === true;
      });
    },

    canEditTimeStamp(date) {
      if (this.userHasPermission('can_edit_all_timesheet_entries')) {
        return true;
      }
      const limitDate = moment().subtract(2, 'days');
      return moment(date).isSameOrAfter(limitDate);
    },

    canAddTimeStamp(date) {
      if (this.userHasPermission('can_edit_all_timesheet_entries')) {
        return moment(date).isSameOrBefore(moment());
      }

      const limitDate = moment().subtract(2, 'days');
      return moment(date).isSameOrAfter(limitDate) && moment(date).isSameOrBefore(moment());
    },

    canAddAbsence(date) {
      if (this.userHasPermission('can_manage_absence_requests')) {
        return true;
      }
      return moment(date).isSameOrAfter(moment());
    },

    canAddCorrectionTime(date) {
      if (this.userHasPermission('can_manage_correction_times')) {
        return moment(date).isSameOrBefore(moment());
      }

      return false;
    },

    getTableRowState(date) {
      if (this.isHoliday(date)) {
        return 'success';
      }

      if (this.isToday(date)) {
        return 'warning';
      }

      if (!this.isWorkingDay(date)) {
        return 'light';
      }

      return 'white';
    },

    openTimeStampPopup(timeStamp) {
      this.currentTimeStamp = timeStamp;
      this.timeStampPopupActive = true;
    },

    openCorrectionTimePopup(correctionTime) {
      this.currentCorrectionTime = correctionTime;
      this.correctionTimePopupActive = true;
    },

    openAbsencePopup(absence) {
      this.currentAbsence = absence;
      this.absencePopupActive = true;
    },

    onClickCreateTimeStamp(date) {
      const timeStamp = ModelFactory.create('time_sheet_entries'),
          currentHour = moment().format('HH'),
          currentMinute = moment().format('mm');

      date = moment(date).set({'hour': currentHour, 'minute': currentMinute, 'second': 0});

      timeStamp.timestamp = date.format('YYYY-MM-DD HH:mm:ss');
      timeStamp.time_sheet_id = this.timeSheet.id;

      this.openTimeStampPopup(timeStamp);
    },
    onClickBulkCreateTimeStamp() {
      const timeStamp = ModelFactory.create('time_sheet_entries');
      timeStamp.time_sheet_id = this.timeSheet.id;

      this.currentTimeStamp = timeStamp;
      this.bulkTimeStampPopupActive = true;
    },

    onClickEditTimeStamp(timeStamp) {
      if (!this.canEditTimeStamp(timeStamp.timestamp)) {
        return false;
      }

      timeStamp.timestamp = moment(timeStamp.timestamp).format('YYYY-MM-DD HH:mm:ss');

      delete timeStamp.created_at;
      delete timeStamp.updated_at;
      delete timeStamp.time_sheet_id;

      this.openTimeStampPopup(timeStamp);
    },

    onClickCreateCorrectionTime(date) {
      const correctionTime = ModelFactory.create('time_sheet_correction_times');

      correctionTime.date = date.format('YYYY-MM-DD');
      correctionTime.time_sheet_id = this.timeSheet.id;

      this.openCorrectionTimePopup(correctionTime);
    },

    onClickEditCorrectionTime(correctionTime) {
      delete correctionTime.created_at;
      delete correctionTime.updated_at;
      delete correctionTime.time_sheet_id;

      this.openCorrectionTimePopup(correctionTime);
    },

    onClickCreateAbsence(date) {
      const absence = ModelFactory.create('absences');

      absence.starts_at = date.format('YYYY-MM-DD HH:mm:ss');
      absence.user_id = this.timeSheet.user_id;
      absence.absence_type_id = null;
      absence.absence_quota_id = null;
      absence.status = null;

      this.openAbsencePopup(absence);
    },

    onClickEditAbsence(absence) {
      delete absence.created_at;
      delete absence.updated_at;
      delete absence.user_id;

      this.openAbsencePopup(absence);
    },

    closeAllPopups() {
      this.absencePopupActive = false;
      this.correctionTimePopupActive = false;
      this.timeStampPopupActive = false;
    },

    saveModel(endpoint, data, name = '') {
      if (data.id !== undefined && data.id !== null) {
        ApiService.put(endpoint + '/' + data.id, data).then(response => {
          this.fetchData();
          this.closeAllPopups();
        }).catch(error => {
          error.handleUnkown = true;
          error.handleGlobally();
        });

        return;
      }

      ApiService.post(endpoint, data).then(response => {
        this.fetchData();
        this.closeAllPopups();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    deleteModel(endpoint, data, name = '') {
      ApiService.delete(endpoint + '/' + data.id).then(response => {
        this.fetchData();
        this.closeAllPopups();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    onSaveTimeStamp(timeStamp) {
      this.saveModel('time-sheet-entry', timeStamp, 'Zeiteintrag')
    },

    onDeleteTimeStamp(timeStamp) {
      this.deleteModel('time-sheet-entry', timeStamp, 'Zeiteintrag')
    },

    onSaveCorrectionTime(correctionTime) {
      this.saveModel('correction-time', correctionTime, 'Korrekturzeit')
    },

    onDeleteCorrectionTime(correctionTime) {
      this.deleteModel('correction-time', correctionTime, 'Korrekturzeit')
    },

    onSaveAbsence(absence) {
      if (!absence.id && !this.userHasPermission('can_manage_absence_requests')) {
        absence.status = 3;
      }

      absence.whole_day = absence.whole_day || false;

      this.saveModel('absence', absence, 'Abszenz')
    },

    onDeleteAbsence(correctionTime) {
      this.deleteModel('absence', correctionTime, 'Abszenz')
    },

    isLocation(location, dataLocation) {
      if (dataLocation == null) {
        return false;
      }
      if (dataLocation == location) {
        return true;
      }
      return false;
    },
    applyActivityLocation(location, date) {
      this.$refs.card.refreshcard();

      const data = {
        user_id: this.timeSheet.user_id,
        date: date.format('YYYY-MM-DD'),
        activity_location: location
      }

      ApiService.post('time-sheet-user-location', data).then(() => {
        this.fetchData();
      }).catch(error => {
        this.$vs.notify({
          title: 'Fehler',
          text: error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        this.$refs.card.removeRefreshAnimation();
        error.handleUnkown = true;
        error.handleGlobally();
      });


    },
    deleteActivityLocation(date) {
      this.$refs.card.refreshcard();

      ApiService.delete(`time-sheet-user-location/${this.timeSheet.user_id}/${date.format('YYYY.MM.DD')}`).then(() => {
        this.fetchData();
      }).catch(error => {
        this.$vs.notify({
          title: 'Fehler',
          text: error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        this.$refs.card.removeRefreshAnimation();
        error.handleUnkown = true;
        error.handleGlobally();
      });


    },
  },

  watch: {
    timeSheets() {
      this.fetchData();
    }

  },

  filters: {
    date(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    day(date) {
      return moment(date).format('dd');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
    month(date) {
      return moment(date).format('MMMM');
    },
    time(date) {
      return moment(date).format('HH:mm');
    },
    number(number) {
      if (!number) {
        number = 0;
      }

      return parseFloat(number).toLocaleString(
          undefined,
          {minimumFractionDigits: 2}
      );
    }
  }

}
</script>

<style scoped>
.timesheet-total-row {
  font-size: 15px;
}

.user-activity-location-icons-container .delete-activity-location {
  display: none;
}

.user-activity-location-icons-container:hover .delete-activity-location {
  display: inline-block;
}

.user-activity-location-icons-container .vs-icon:hover {
  cursor: pointer;
}

.user-activity-location-icons-container .text-muted {
  color: #e1e1e1;
}

.user-activity-location-icons-container .delete-activity-location {
  color: red;
}
</style>